/* ----- TEMPORARY H1 TITLE FOR UPCOMING EVENT NOTIFICATION ----- */
.temp_h1 {
  font-size: 3rem;
  text-align: center;
  margin: 10rem 0;
}

.trip_table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0 auto;
  margin-bottom: 5rem;
  padding: 0;
  width: 100%;
  max-width: 120rem;
  table-layout: fixed;
}

.trip_table caption:first-of-type {
  font-size: 3rem;
  margin: 6rem 0;
}

.trip_table caption:nth-of-type(2) {
  font-size: 1.6rem;
  margin: 2rem 0;
}

.trip_table caption:nth-of-type(2) a {
  text-decoration: none;
  color: blue;
}

.trip_table thead {
  text-transform: uppercase;
}

.trip_table tr {
  border: 1px solid rgba(128, 128, 128, 0.334);
}

.trip_table td,
.trip_table th {
  padding: 1rem 0;
}

.trip_table td {
  text-align: center;
  font-size: 2rem;
}

.trip_table td a {
  text-decoration: none;
  color: var(--color-primary-dark);
  position: relative;
  cursor: pointer;
  padding: 0.2rem 1rem;
  overflow: hidden;
  display: inline-block;
}

.trip_table td a::before {
  position: absolute;
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  background-color: var(--color-primary);
  top: 0;
  left: 0;
  z-index: -1;
  transform: translateX(-90%) skew(-20deg);
  transition: 0.2s all;
}

.trip_table td a:hover::before {
  transform: translateX(-4%) skew(-20deg);
}

.trip_table tbody tr:hover {
  background-color: rgba(128, 128, 128, 0.091);
}

@media screen and (max-width: 600px) {
  .trip_table caption:first-of-type {
    font-size: 2.4rem;
  }

  .trip_table caption:nth-of-type(2) {
    font-size: 1.6rem;
    padding: 0 1rem;
  }

  .trip_table td {
    font-size: 1.6rem;
    font-weight: bold;
    padding: 0 0.5rem;
  }

  .trip_table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .trip_table td::before {
    content: attr(data-label);
    float: left;
    text-transform: uppercase;
    font-size: 0.8rem;
  }
}
