.title {
  font-size: 3rem;
  text-transform: uppercase;
  text-align: center;
  padding: 7rem 2rem;
}

.wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 6rem;
}

.wrapper img {
  max-width: 40rem;
  margin-right: 7rem;
  border-radius: 1000px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
  transition: 0.1s linear all;
}

.wrapper img:nth-child(2) {
  transform: rotate(-20deg) translateX(-50px) translateY(-10px);
  z-index: 10;
}

.wrapper img:nth-child(3) {
  transform: rotate(-40deg) translateX(-90px) translateY(-40px);
  z-index: 1;
}

.wrapper img:hover {
  scale: 1.07;
}

.wrapper img:nth-child(2):hover {
  z-index: 30;
}

.wrapper img:nth-child(3):hover {
  z-index: 30;
}

.wrapper .photo_wrapper {
  position: relative;
  width: 500px;
  height: 600px;
}

.wrapper div + div {
  align-self: center;
  padding-left: 4.5rem;
}

.wrapper p {
  font-size: 2rem;
  position: relative;
}

.wrapper p::before {
  position: absolute;
  top: 2px;
  left: -30px;
  content: "";
  background: url("../../assets/paw.png") no-repeat center center/cover;
  width: 2.1rem;
  height: 2.1rem;
  z-index: 200;
  transform: rotate(30deg);
}

.wrapper p:not(:last-child) {
  margin-bottom: 5rem;
}

@media screen and (max-width: 1370px) {
  .wrapper {
    flex-direction: column;
  }

  .wrapper .photo_wrapper {
    display: flex;
    height: 100%;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-bottom: 6rem;
  }

  .wrapper img {
    display: block;
    position: inherit;
    transform: none !important;
    max-width: 23rem;

    margin-right: unset;
  }
}

@media screen and (max-width: 710px) {
  .wrapper img:not(:nth-child(1)) {
    display: none;
  }

  .wrapper img:first-child {
    max-width: 19rem;
  }
}
@media screen and (max-width: 550px) {
  .wrapper div {
    width: 100%;
  }
}

@media screen and (max-width: 380px) {
  .title {
    font-size: 2.4rem;
  }

  .wrapper p {
    font-size: 1.6rem;
  }
}
