.header {
  background-color: var(--color-primary);
  padding: 4rem;
}

.header h1 {
  font-size: 2rem;
}

@media screen and (max-width: 710px) {
  .header {
    transform: translateY(-20px);
  }
}
