nav {
  display: flex;
  justify-content: center;
  padding: 2rem 3rem 0 3rem;
  z-index: 3000;
  position: relative;
}

.nav a {
  padding: 2rem 2rem 1rem 2rem;
  font-size: 1.8rem;
  text-decoration: none;
  color: black;
  transition: all 0.1s linear;
}

.nav a:not(:first-child) {
  margin-left: 3rem;
}

a.active {
  border-bottom: 2px solid var(--color-primary);
  font-weight: bold;
}

.nav a:hover {
  transform: scale(1.15);
}

@media screen and (max-width: 850px) {
  .nav {
    display: none;
  }
}
