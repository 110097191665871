@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&display=swap);
/* general setup */

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
  box-sizing: border-box;
  background-color: #f6f6f6;
  background-color: var(--color-primary-light);
  font-family: "Open Sans", sans-serif;
  color: #414143;
  color: var(--color-primary-dark);
}

/* variables */
:root {
  --color-primary: #74c2c4;
  --color-primary-light: #f6f6f6;
  --color-primary-dark: #414143;
}

.carousel-root {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 3rem 0;
}

.Header_wrapper__1n26p {
  background-color: var(--color-primary);
  display: flex;
  justify-content: space-evenly;
  padding-top: 5rem;
  padding-bottom: 2rem;
  color: var(--color-primary-dark);
  z-index: 200;
  position: relative;
}

.Header_wrapper__1n26p h1 {
  font-size: 3rem;
  letter-spacing: 2px;
  margin-bottom: 1rem;
}

.Header_wrapper__1n26p p {
  font-size: 1.8rem;
}

.Header_left__JRIOx {
  display: flex;
  align-items: center;
}

.Header_left__JRIOx div {
  display: flex;
  flex-direction: column;
  margin-left: 2rem;
}

.Header_logo__3EvUy {
  max-height: 300px;
  border-radius: 2px;
}

.Header_header_photo__zXWsW {
  max-width: 500px;
  border-radius: 10px;
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.28);
}

.Header_wave__393Jw {
  width: 100%;
  -webkit-transform: translateY(-1px);
          transform: translateY(-1px);
}

@media screen and (max-width: 1370px) {
  .Header_header_photo__zXWsW {
    max-width: 280px;
  }

  .Header_logo__3EvUy {
    max-height: 150px;
  }
}

@media screen and (max-width: 740px) {
  .Header_header_photo__zXWsW {
    display: none;
  }
}

@media screen and (max-width: 850px) {
  .Header_wrapper__1n26p {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
  }

  .Header_wave__393Jw {
    -webkit-transform: translateY(-22px);
            transform: translateY(-22px);
  }
}

@media screen and (max-width: 490px) {
  .Header_left__JRIOx {
    padding: 0 1rem;
  }

  .Header_logo__3EvUy {
    max-width: 15rem;
  }

  .Header_wrapper__1n26p h1 {
    font-size: 2.6rem;
  }
}

@media screen and (max-width: 380px) {
  .Header_logo__3EvUy {
    max-width: 13rem;
  }

  .Header_wrapper__1n26p h1 {
    font-size: 2.4rem;
  }

  .Header_wrapper__1n26p p {
    font-size: 1.6rem;
  }
}

.Maps_map__cza2w {
  width: 120rem;
  height: 60rem;
  border-radius: 10px;
  margin: 0 auto;
  margin-bottom: 4rem;
}

.Maps_wrapper__HenkH h2 {
  font-size: 3rem;
  font-weight: 300;
  text-align: center;
  padding: 3rem 0 5rem 0;
}

.Maps_wrapper__HenkH {
  background-color: var(--color-primary);
  padding: 2rem 0;
}

.Maps_waveTop__9Vk_L {
  rotate: 180deg;
  width: 100%;
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
}

.Maps_waveBot__3AK_Y {
  -webkit-transform: scaleX(-1) translateY(-5px);
          transform: scaleX(-1) translateY(-5px);
  width: 100%;
}

@media screen and (max-width: 1370px) {
  .Maps_wrapper__HenkH {
    padding: 0.5rem 0;
  }

  .Maps_map__cza2w {
    width: 100rem;
  }
}

@media screen and (max-width: 1024px) {
  .Maps_map__cza2w {
    width: 80rem;
  }

  .Maps_wrapper__HenkH h2 {
    padding: 3rem 2rem 5rem 2rem;
  }
}

@media screen and (max-width: 830px) {
  .Maps_wrapper__HenkH {
    padding: 0.5rem 0;
  }
  .Maps_map__cza2w {
    width: 60rem;
  }
}

@media screen and (max-width: 630px) {
  .Maps_map__cza2w {
    width: 100%;
  }
}

@media screen and (max-width: 380px) {
  .Maps_wrapper__HenkH h2 {
    font-size: 2.4rem;
    padding: 3rem 0.5rem 5rem 0.5rem;
  }
}

.Maps_map_wrapper__LL7Cw {
  background-color: var(--color-primary);
  display: flex;
  align-items: center;
  justify-content: center;
}

iframe {
  margin: 5rem 0 10rem 0;
  border: none;
  border-radius: 5px;
  height: 600px;
  width: 80%;
  max-width: 200rem;
}

@media screen and (max-width: 850px) {
  iframe {
    margin: 5rem 0 10rem 0;
    border: none;
    border-radius: 5px;
    height: 600px;
    width: 100%;
  }
}

.WhyUs_title__D3VUg {
  font-size: 3rem;
  text-transform: uppercase;
  text-align: center;
  padding: 7rem 2rem;
}

.WhyUs_wrapper__1wQpR {
  display: flex;
  justify-content: center;
  margin-bottom: 6rem;
}

.WhyUs_wrapper__1wQpR img {
  max-width: 40rem;
  margin-right: 7rem;
  border-radius: 1000px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
  transition: 0.1s linear all;
}

.WhyUs_wrapper__1wQpR img:nth-child(2) {
  -webkit-transform: rotate(-20deg) translateX(-50px) translateY(-10px);
          transform: rotate(-20deg) translateX(-50px) translateY(-10px);
  z-index: 10;
}

.WhyUs_wrapper__1wQpR img:nth-child(3) {
  -webkit-transform: rotate(-40deg) translateX(-90px) translateY(-40px);
          transform: rotate(-40deg) translateX(-90px) translateY(-40px);
  z-index: 1;
}

.WhyUs_wrapper__1wQpR img:hover {
  scale: 1.07;
}

.WhyUs_wrapper__1wQpR img:nth-child(2):hover {
  z-index: 30;
}

.WhyUs_wrapper__1wQpR img:nth-child(3):hover {
  z-index: 30;
}

.WhyUs_wrapper__1wQpR .WhyUs_photo_wrapper__1Gukw {
  position: relative;
  width: 500px;
  height: 600px;
}

.WhyUs_wrapper__1wQpR div + div {
  align-self: center;
  padding-left: 4.5rem;
}

.WhyUs_wrapper__1wQpR p {
  font-size: 2rem;
  position: relative;
}

.WhyUs_wrapper__1wQpR p::before {
  position: absolute;
  top: 2px;
  left: -30px;
  content: "";
  background: url(/static/media/paw.27138aa9.png) no-repeat center center/cover;
  width: 2.1rem;
  height: 2.1rem;
  z-index: 200;
  -webkit-transform: rotate(30deg);
          transform: rotate(30deg);
}

.WhyUs_wrapper__1wQpR p:not(:last-child) {
  margin-bottom: 5rem;
}

@media screen and (max-width: 1370px) {
  .WhyUs_wrapper__1wQpR {
    flex-direction: column;
  }

  .WhyUs_wrapper__1wQpR .WhyUs_photo_wrapper__1Gukw {
    display: flex;
    height: 100%;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-bottom: 6rem;
  }

  .WhyUs_wrapper__1wQpR img {
    display: block;
    position: inherit;
    -webkit-transform: none !important;
            transform: none !important;
    max-width: 23rem;

    margin-right: unset;
  }
}

@media screen and (max-width: 710px) {
  .WhyUs_wrapper__1wQpR img:not(:nth-child(1)) {
    display: none;
  }

  .WhyUs_wrapper__1wQpR img:first-child {
    max-width: 19rem;
  }
}
@media screen and (max-width: 550px) {
  .WhyUs_wrapper__1wQpR div {
    width: 100%;
  }
}

@media screen and (max-width: 380px) {
  .WhyUs_title__D3VUg {
    font-size: 2.4rem;
  }

  .WhyUs_wrapper__1wQpR p {
    font-size: 1.6rem;
  }
}

.Footer_footer__BKhgK {
  display: flex;
  justify-content: space-evenly;
  font-size: 1.6rem;
}

.Footer_footer_left__5cDVj p {
  color: var(--color-primary-dark);
}

.Footer_footer_left__5cDVj,
.Footer_footer_right__2rp11,
.Footer_local__2vFWD {
  padding: 6rem 6rem 2rem 6rem;
  text-align: center;
}

.Footer_footer_right__2rp11 a,
.Footer_local__2vFWD a {
  text-decoration: none;
  color: var(--color-primary-dark);
}

.Footer_footer__BKhgK img {
  width: 32px;
  margin-top: 1rem;
}

.Footer_footer__BKhgK .Footer_insta__3aZ3b {
  margin-right: 2rem;
}

.Footer_footer_left__5cDVj p:nth-child(2) {
  font-size: 1.2rem;
}

@media screen and (max-width: 710px) {
  .Footer_footer_left__5cDVj,
  .Footer_footer_right__2rp11,
  .Footer_local__2vFWD {
    padding: 0;
  }
}
@media screen and (max-width: 630px) {
  .Footer_footer__BKhgK {
    flex-direction: column;
    align-items: center;
  }

  .Footer_footer_left__5cDVj,
  .Footer_footer_right__2rp11,
  .Footer_local__2vFWD {
    text-align: center;
    padding-bottom: 2.5rem;
    font-size: 1.3rem;
  }
}

.CourseGridElement_gridCard__359V9 {
  height: 20rem;
  position: relative;
  font-size: 3rem;
  color: var(--color-primary);
  transition: all 0.1s linear;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.CourseGridElement_gridCard__359V9 p {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-image: linear-gradient(
    to left,
    rgba(32, 32, 32, 0.064),
    rgba(0, 0, 0, 0.612)
  );
  width: -webkit-max-content;
  width: max-content;
  padding: 0.5rem 1rem;
}

.CourseGridElement_gridCard__359V9:hover {
  cursor: pointer;
  font-size: 3.3rem;
}

.CourseGridElement_gridCard__359V9 img {
  width: 100%;
  -webkit-filter: blur(1.5px) brightness(80%);
          filter: blur(1.5px) brightness(80%);
}

.CourseGrid_courseGrid__3xJPl {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-gap: 1.5rem;
  gap: 1.5rem;
  padding: 4rem 0.5rem;
}

@media (min-width: 600px) {
  .CourseGrid_courseGrid__3xJPl {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 900px) {
  .CourseGrid_courseGrid__3xJPl {
    grid-template-columns: repeat(3, 1fr);
  }
}

.CourseHeader_header__2N5e1 {
  background-color: var(--color-primary);
  padding: 4rem;
}

.CourseHeader_header__2N5e1 h1 {
  font-size: 2rem;
}

@media screen and (max-width: 710px) {
  .CourseHeader_header__2N5e1 {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
  }
}

nav {
  display: flex;
  justify-content: center;
  padding: 2rem 3rem 0 3rem;
  z-index: 3000;
  position: relative;
}

.Nav_nav__2l-YZ a {
  padding: 2rem 2rem 1rem 2rem;
  font-size: 1.8rem;
  text-decoration: none;
  color: black;
  transition: all 0.1s linear;
}

.Nav_nav__2l-YZ a:not(:first-child) {
  margin-left: 3rem;
}

a.Nav_active__E3QNQ {
  border-bottom: 2px solid var(--color-primary);
  font-weight: bold;
}

.Nav_nav__2l-YZ a:hover {
  -webkit-transform: scale(1.15);
          transform: scale(1.15);
}

@media screen and (max-width: 850px) {
  .Nav_nav__2l-YZ {
    display: none;
  }
}

.NavMobile_nav__3Ujy3 {
  display: none;
}

.NavMobile_hamburgerBtn__2aHO9 {
  display: none;
  background-color: transparent;
  width: 40px;
  height: 40px;
  z-index: 4000;
  position: fixed;
  top: 20px;
  right: 20px;
  border: none;
}

.NavMobile_span1__3l1zA,
.NavMobile_span2__JFVmq,
.NavMobile_span3__HjUyP {
  border: 1px solid var(--color-primary-dark);
  display: block;
}

.NavMobile_span1__3l1zA {
  -webkit-transform: translateY(-7px);
          transform: translateY(-7px);
}

.NavMobile_span3__HjUyP {
  -webkit-transform: translateY(7px);
          transform: translateY(7px);
}

.NavMobile_navList__KM0TU {
  background-color: var(--color-primary);
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 280;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.NavMobile_navList__KM0TU a {
  font-size: 2rem;
  text-decoration: none;
  color: var(--color-primary-dark);
  font-weight: bold;
  padding: 1rem 30%;
}

.NavMobile_navList__KM0TU.NavMobile_isClosed__3JkDQ {
  -webkit-animation: NavMobile_slideOut__2XGs4 0.8s cubic-bezier(0.85, 0, 0.15, 1) forwards;
          animation: NavMobile_slideOut__2XGs4 0.8s cubic-bezier(0.85, 0, 0.15, 1) forwards;
}

.NavMobile_navList__KM0TU.NavMobile_isOpen__-Otej {
  -webkit-animation: NavMobile_slideIn__2wjkN 0.8s cubic-bezier(0.85, 0, 0.15, 1) forwards;
          animation: NavMobile_slideIn__2wjkN 0.8s cubic-bezier(0.85, 0, 0.15, 1) forwards;
}

@-webkit-keyframes NavMobile_slideIn__2wjkN {
  from {
    left: 100%;
  }
  to {
    left: 0%;
  }
}

@keyframes NavMobile_slideIn__2wjkN {
  from {
    left: 100%;
  }
  to {
    left: 0%;
  }
}
@-webkit-keyframes NavMobile_slideOut__2XGs4 {
  from {
    left: 0%;
  }
  to {
    left: 100%;
  }
}
@keyframes NavMobile_slideOut__2XGs4 {
  from {
    left: 0%;
  }
  to {
    left: 100%;
  }
}

.NavMobile_span1__3l1zA.NavMobile_isOpen__-Otej {
  -webkit-animation: NavMobile_span1rotateIn__TEYdc 0.25s linear forwards;
          animation: NavMobile_span1rotateIn__TEYdc 0.25s linear forwards;
}
.NavMobile_span2__JFVmq.NavMobile_isOpen__-Otej {
  -webkit-animation: NavMobile_span2rotateIn__7F4v_ 0.25s linear forwards;
          animation: NavMobile_span2rotateIn__7F4v_ 0.25s linear forwards;
}
.NavMobile_span3__HjUyP.NavMobile_isOpen__-Otej {
  -webkit-animation: NavMobile_span3fadeOut__4riJ7 0.1s linear forwards;
          animation: NavMobile_span3fadeOut__4riJ7 0.1s linear forwards;
}

@-webkit-keyframes NavMobile_span1rotateIn__TEYdc {
  from {
  }
  to {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
  }
}

@keyframes NavMobile_span1rotateIn__TEYdc {
  from {
  }
  to {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
  }
}

@-webkit-keyframes NavMobile_span2rotateIn__7F4v_ {
  from {
  }
  to {
    -webkit-transform: rotate(45deg) translateY(-2px);
            transform: rotate(45deg) translateY(-2px);
  }
}

@keyframes NavMobile_span2rotateIn__7F4v_ {
  from {
  }
  to {
    -webkit-transform: rotate(45deg) translateY(-2px);
            transform: rotate(45deg) translateY(-2px);
  }
}

@-webkit-keyframes NavMobile_span3fadeOut__4riJ7 {
  from {
    opacity: 100%;
  }
  to {
    opacity: 0%;
  }
}

@keyframes NavMobile_span3fadeOut__4riJ7 {
  from {
    opacity: 100%;
  }
  to {
    opacity: 0%;
  }
}

.NavMobile_span1__3l1zA.NavMobile_isClosed__3JkDQ {
  -webkit-animation: NavMobile_span1rotateOut__eLLan 0.25s linear forwards;
          animation: NavMobile_span1rotateOut__eLLan 0.25s linear forwards;
}
.NavMobile_span2__JFVmq.NavMobile_isClosed__3JkDQ {
  -webkit-animation: NavMobile_span2rotateOut__2vqf_ 0.25s linear forwards;
          animation: NavMobile_span2rotateOut__2vqf_ 0.25s linear forwards;
}
.NavMobile_span3__HjUyP.NavMobile_isClosed__3JkDQ {
  -webkit-animation: NavMobile_span3fadeIn__30Xkb 0.1s linear forwards;
          animation: NavMobile_span3fadeIn__30Xkb 0.1s linear forwards;
}

@-webkit-keyframes NavMobile_span1rotateOut__eLLan {
  from {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
  }
  to {
  }
}

@keyframes NavMobile_span1rotateOut__eLLan {
  from {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
  }
  to {
  }
}

@-webkit-keyframes NavMobile_span2rotateOut__2vqf_ {
  from {
    -webkit-transform: rotate(45deg) translateY(-2px);
            transform: rotate(45deg) translateY(-2px);
  }
  to {
  }
}

@keyframes NavMobile_span2rotateOut__2vqf_ {
  from {
    -webkit-transform: rotate(45deg) translateY(-2px);
            transform: rotate(45deg) translateY(-2px);
  }
  to {
  }
}

@-webkit-keyframes NavMobile_span3fadeIn__30Xkb {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes NavMobile_span3fadeIn__30Xkb {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media screen and (max-width: 850px) {
  .NavMobile_nav__3Ujy3 {
    display: block;
    position: relative;
  }

  .NavMobile_hamburgerBtn__2aHO9 {
    display: block;
  }
}

.CourseDetail_title__3D-my {
  background: linear-gradient(
    to right,
    var(--color-primary),
    var(--color-primary-light) 90%
  );
  color: white;
  font-size: 2.8rem;
  padding: 3rem 5rem;
}

.CourseDetail_grid_container__lG1U8 {
  max-width: 1200px;
  margin: 0 auto;
  padding: 10rem 0;
  display: grid;
  grid-row-gap: 8rem;
  row-gap: 8rem;
  align-items: flex-start;
  grid-template:
    "overview image1"
    "image2 numbers";
}

.CourseDetail_overview__3XbCt {
  grid-area: overview;
}
.CourseDetail_numbers__4Wt4p {
  grid-area: numbers;
}
.CourseDetail_image1__2_7ZO {
  grid-area: image1;
}
.CourseDetail_image2__2Yx8y {
  grid-area: image2;
}

.CourseDetail_grid_container__lG1U8 div {
  padding: 0 3rem;
}

.CourseDetail_grid_container__lG1U8 img {
  max-width: 40rem;
  justify-self: center;
  border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
}

.CourseDetail_grid_container__lG1U8 img:first-of-type {
  -webkit-animation: CourseDetail_animateBorder1__O8fFj 15s linear infinite;
          animation: CourseDetail_animateBorder1__O8fFj 15s linear infinite;
}

.CourseDetail_grid_container__lG1U8 img:last-of-type {
  -webkit-animation: CourseDetail_animateBorder2__38Kjr 15s linear infinite;
          animation: CourseDetail_animateBorder2__38Kjr 15s linear infinite;
}

.CourseDetail_grid_container__lG1U8 h2,
.CourseDetail_grid_container__lG1U8 h3 {
  font-size: 2.4rem;
  margin-bottom: 4rem;
}

.CourseDetail_grid_container__lG1U8 p {
  font-size: 2rem;
}

.CourseDetail_grid_container__lG1U8 button {
  border: none;
  border-radius: 5px;
  padding: 2rem 4rem;
  margin-top: 3rem;
  color: var(--color-primary-dark);
  font-size: 2rem;
  background-color: #80dade;
}

.CourseDetail_grid_container__lG1U8 button:hover {
  cursor: pointer;
  scale: 1.1;
  background-color: #88e6eb;
}

@-webkit-keyframes CourseDetail_animateBorder1__O8fFj {
  0% {
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  }
  35% {
    border-radius: 70% 30% 50% 10% / 50% 90% 20% 40%;
  }
  70% {
    border-radius: 90% 10% 30% 80% / 30% 80% 50% 10%;
  }
  100% {
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  }
}

@keyframes CourseDetail_animateBorder1__O8fFj {
  0% {
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  }
  35% {
    border-radius: 70% 30% 50% 10% / 50% 90% 20% 40%;
  }
  70% {
    border-radius: 90% 10% 30% 80% / 30% 80% 50% 10%;
  }
  100% {
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  }
}

@-webkit-keyframes CourseDetail_animateBorder2__38Kjr {
  0% {
    border-radius: 62% 38% 70% 30% / 64% 59% 41% 36%;
  }
  35% {
    border-radius: 90% 10% 30% 80% / 30% 80% 50% 10%;
  }
  70% {
    border-radius: 70% 30% 50% 10% / 50% 90% 20% 40%;
  }
  100% {
    border-radius: 62% 38% 70% 30% / 64% 59% 41% 36%;
  }
}

@keyframes CourseDetail_animateBorder2__38Kjr {
  0% {
    border-radius: 62% 38% 70% 30% / 64% 59% 41% 36%;
  }
  35% {
    border-radius: 90% 10% 30% 80% / 30% 80% 50% 10%;
  }
  70% {
    border-radius: 70% 30% 50% 10% / 50% 90% 20% 40%;
  }
  100% {
    border-radius: 62% 38% 70% 30% / 64% 59% 41% 36%;
  }
}

@media (max-width: 940px) {
  .CourseDetail_grid_container__lG1U8 {
    grid-template:
      "overview"
      "image1"
      "numbers"
      "image2";
  }

  .CourseDetail_title__3D-my {
    font-size: 2rem;
    padding: 2rem 2rem;
  }

  .CourseDetail_grid_container__lG1U8 h2,
  .CourseDetail_grid_container__lG1U8 h3 {
    font-size: 2.2rem;
    margin-bottom: 4rem;
  }

  .CourseDetail_grid_container__lG1U8 p {
    font-size: 1.8rem;
  }

  .CourseDetail_grid_container__lG1U8 img {
    max-width: 30rem;
  }
}

.Teacher_teacher_card__2P9Mo {
  width: 45rem;
  margin: 1rem;
}

.Teacher_teacher_image_caroussel__wt1-4 {
  display: flex;
  justify-content: center;
  height: 40rem;
}

.Teacher_teacher_card__2P9Mo img {
  max-width: 45rem;
  border-radius: 3px;
}

.Teacher_bio_btn__2RnTV {
  border: none;
  border-radius: 3px;
  background-color: var(--color-primary);
  padding: 1rem 1.7rem;
  margin-top: 0.8rem;
}

.Teacher_bio_btn__2RnTV:hover {
  cursor: pointer;
  scale: 1.05;
}

.Teacher_teacher_card__2P9Mo a {
  text-decoration: none;
  color: var(--color-primary-dark);
}

.Teacher_teacher_card__2P9Mo h2 {
  font-size: 2.2rem;
  padding: 0.1rem 1rem;
  margin-top: 1rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
  overflow: hidden;
}

.Teacher_teacher_card__2P9Mo h2::before {
  position: absolute;
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  background-color: var(--color-primary);
  top: 0;
  left: 0;
  z-index: -1;
  -webkit-transform: translateX(-90%) skew(-20deg);
          transform: translateX(-90%) skew(-20deg);
  transition: 0.2s all;
}

.Teacher_teacher_card__2P9Mo h2:hover::before {
  -webkit-transform: translateX(-3%) skew(-20deg);
          transform: translateX(-3%) skew(-20deg);
}

.Teacher_teacher_card__2P9Mo p {
  font-size: 2rem;
}

@media screen and (max-width: 710px) {
  .Teacher_bio_btn__2RnTV:hover {
    scale: 1;
  }
}

@media screen and (max-width: 600px) {
  .Teacher_teacher_card__2P9Mo img {
    max-width: 35rem;
  }
}

@media screen and (max-width: 350px) {
  .Teacher_teacher_card__2P9Mo img {
    max-width: 30rem;
  }
}

.Teachers_teachers_flex__2S5bx {
  width: 100%;
  max-width: 2000px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 2rem;
}

.About_about__eBiEh {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5rem 3rem;
}

.About_about__eBiEh h1 {
  font-size: 3rem;
  margin-bottom: 8rem;
  position: relative;
}

.About_about__eBiEh h1::before {
  position: absolute;
  content: "";
  display: block;
  width: 55%;
  height: 2px;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  background-color: var(--color-primary);
}

.About_about_info__2aqB0 {
  max-width: 900px;
}

.About_about__eBiEh p {
  font-size: 2rem;
  -webkit-animation: About_animateInfo__2lcJ- 1s 0.15s forwards linear;
          animation: About_animateInfo__2lcJ- 1s 0.15s forwards linear;
  opacity: 0;
}

@-webkit-keyframes About_animateInfo__2lcJ- {
  from {
    -webkit-transform: translateY(100px);
            transform: translateY(100px);
  }
  to {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes About_animateInfo__2lcJ- {
  from {
    -webkit-transform: translateY(100px);
            transform: translateY(100px);
  }
  to {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    opacity: 1;
  }
}

.Location_location_frame__HsZDG {
  position: relative;
  padding-top: 20rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 2048px;
}

.Location_banda1__1ynkJ {
  position: absolute;
  top: -200px;
  left: 0;
  z-index: 200;
  scale: 1.5;
}

.Location_banda2__3BtuR {
  position: absolute;
  top: -80px;
  z-index: 200;
  right: 10rem;
  scale: 1.2;
}

.Location_location_infos__3Pk7s {
  background-color: var(--color-primary);
  padding: 10rem 2rem;
  z-index: 100;
}

.Location_location_infos__3Pk7s h1,
.Location_location_infos__3Pk7s h2,
.Location_location_infos__3Pk7s h3 {
  font-size: 3rem;
}

.Location_location_infos__3Pk7s p {
  font-size: 2rem;
  margin: 2rem 0;
  max-width: 70%;
}

.Location_carousel_img__4-LVK {
  max-width: 70rem;
}

@media screen and (max-width: 1225px) {
  .Location_banda1__1ynkJ,
  .Location_banda2__3BtuR {
    scale: 0.9;
    right: 0;
  }

  .Location_banda1__1ynkJ {
    top: -150px;
    left: -100px;
  }
}

@media screen and (max-width: 860px) {
  .Location_banda1__1ynkJ {
    display: none;
  }
}

@media screen and (max-width: 550px) {
  .Location_banda2__3BtuR {
    scale: 0.7;
    right: -50px;
    top: -30px;
  }

  .Location_location_infos__3Pk7s h1,
  .Location_location_infos__3Pk7s h2,
  .Location_location_infos__3Pk7s h3 {
    font-size: 2rem;
  }

  .Location_location_infos__3Pk7s p {
    font-size: 1.8rem;
    max-width: 100%;
  }
}

@media screen and (max-width: 450px) {
  .Location_banda2__3BtuR {
    right: -95px;
  }

  .Location_location_frame__HsZDG {
    overflow: hidden;
  }
}

/* ----- TEMPORARY H1 TITLE FOR UPCOMING EVENT NOTIFICATION ----- */
.Trip_temp_h1__3XX9F {
  font-size: 3rem;
  text-align: center;
  margin: 10rem 0;
}

.Trip_trip_table__KLesP {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0 auto;
  margin-bottom: 5rem;
  padding: 0;
  width: 100%;
  max-width: 120rem;
  table-layout: fixed;
}

.Trip_trip_table__KLesP caption:first-of-type {
  font-size: 3rem;
  margin: 6rem 0;
}

.Trip_trip_table__KLesP caption:nth-of-type(2) {
  font-size: 1.6rem;
  margin: 2rem 0;
}

.Trip_trip_table__KLesP caption:nth-of-type(2) a {
  text-decoration: none;
  color: blue;
}

.Trip_trip_table__KLesP thead {
  text-transform: uppercase;
}

.Trip_trip_table__KLesP tr {
  border: 1px solid rgba(128, 128, 128, 0.334);
}

.Trip_trip_table__KLesP td,
.Trip_trip_table__KLesP th {
  padding: 1rem 0;
}

.Trip_trip_table__KLesP td {
  text-align: center;
  font-size: 2rem;
}

.Trip_trip_table__KLesP td a {
  text-decoration: none;
  color: var(--color-primary-dark);
  position: relative;
  cursor: pointer;
  padding: 0.2rem 1rem;
  overflow: hidden;
  display: inline-block;
}

.Trip_trip_table__KLesP td a::before {
  position: absolute;
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  background-color: var(--color-primary);
  top: 0;
  left: 0;
  z-index: -1;
  -webkit-transform: translateX(-90%) skew(-20deg);
          transform: translateX(-90%) skew(-20deg);
  transition: 0.2s all;
}

.Trip_trip_table__KLesP td a:hover::before {
  -webkit-transform: translateX(-4%) skew(-20deg);
          transform: translateX(-4%) skew(-20deg);
}

.Trip_trip_table__KLesP tbody tr:hover {
  background-color: rgba(128, 128, 128, 0.091);
}

@media screen and (max-width: 600px) {
  .Trip_trip_table__KLesP caption:first-of-type {
    font-size: 2.4rem;
  }

  .Trip_trip_table__KLesP caption:nth-of-type(2) {
    font-size: 1.6rem;
    padding: 0 1rem;
  }

  .Trip_trip_table__KLesP td {
    font-size: 1.6rem;
    font-weight: bold;
    padding: 0 0.5rem;
  }

  .Trip_trip_table__KLesP thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .Trip_trip_table__KLesP td::before {
    content: attr(data-label);
    float: left;
    text-transform: uppercase;
    font-size: 0.8rem;
  }
}

