.footer {
  display: flex;
  justify-content: space-evenly;
  font-size: 1.6rem;
}

.footer_left p {
  color: var(--color-primary-dark);
}

.footer_left,
.footer_right,
.local {
  padding: 6rem 6rem 2rem 6rem;
  text-align: center;
}

.footer_right a,
.local a {
  text-decoration: none;
  color: var(--color-primary-dark);
}

.footer img {
  width: 32px;
  margin-top: 1rem;
}

.footer .insta {
  margin-right: 2rem;
}

.footer_left p:nth-child(2) {
  font-size: 1.2rem;
}

@media screen and (max-width: 710px) {
  .footer_left,
  .footer_right,
  .local {
    padding: 0;
  }
}
@media screen and (max-width: 630px) {
  .footer {
    flex-direction: column;
    align-items: center;
  }

  .footer_left,
  .footer_right,
  .local {
    text-align: center;
    padding-bottom: 2.5rem;
    font-size: 1.3rem;
  }
}
