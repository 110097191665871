.title {
  background: linear-gradient(
    to right,
    var(--color-primary),
    var(--color-primary-light) 90%
  );
  color: white;
  font-size: 2.8rem;
  padding: 3rem 5rem;
}

.grid_container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 10rem 0;
  display: grid;
  row-gap: 8rem;
  align-items: flex-start;
  grid-template:
    "overview image1"
    "image2 numbers";
}

.overview {
  grid-area: overview;
}
.numbers {
  grid-area: numbers;
}
.image1 {
  grid-area: image1;
}
.image2 {
  grid-area: image2;
}

.grid_container div {
  padding: 0 3rem;
}

.grid_container img {
  max-width: 40rem;
  justify-self: center;
  border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
}

.grid_container img:first-of-type {
  animation: animateBorder1 15s linear infinite;
}

.grid_container img:last-of-type {
  animation: animateBorder2 15s linear infinite;
}

.grid_container h2,
.grid_container h3 {
  font-size: 2.4rem;
  margin-bottom: 4rem;
}

.grid_container p {
  font-size: 2rem;
}

.grid_container button {
  border: none;
  border-radius: 5px;
  padding: 2rem 4rem;
  margin-top: 3rem;
  color: var(--color-primary-dark);
  font-size: 2rem;
  background-color: #80dade;
}

.grid_container button:hover {
  cursor: pointer;
  scale: 1.1;
  background-color: #88e6eb;
}

@keyframes animateBorder1 {
  0% {
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  }
  35% {
    border-radius: 70% 30% 50% 10% / 50% 90% 20% 40%;
  }
  70% {
    border-radius: 90% 10% 30% 80% / 30% 80% 50% 10%;
  }
  100% {
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  }
}

@keyframes animateBorder2 {
  0% {
    border-radius: 62% 38% 70% 30% / 64% 59% 41% 36%;
  }
  35% {
    border-radius: 90% 10% 30% 80% / 30% 80% 50% 10%;
  }
  70% {
    border-radius: 70% 30% 50% 10% / 50% 90% 20% 40%;
  }
  100% {
    border-radius: 62% 38% 70% 30% / 64% 59% 41% 36%;
  }
}

@media (max-width: 940px) {
  .grid_container {
    grid-template:
      "overview"
      "image1"
      "numbers"
      "image2";
  }

  .title {
    font-size: 2rem;
    padding: 2rem 2rem;
  }

  .grid_container h2,
  .grid_container h3 {
    font-size: 2.2rem;
    margin-bottom: 4rem;
  }

  .grid_container p {
    font-size: 1.8rem;
  }

  .grid_container img {
    max-width: 30rem;
  }
}
