.teacher_card {
  width: 45rem;
  margin: 1rem;
}

.teacher_image_caroussel {
  display: flex;
  justify-content: center;
  height: 40rem;
}

.teacher_card img {
  max-width: 45rem;
  border-radius: 3px;
}

.bio_btn {
  border: none;
  border-radius: 3px;
  background-color: var(--color-primary);
  padding: 1rem 1.7rem;
  margin-top: 0.8rem;
}

.bio_btn:hover {
  cursor: pointer;
  scale: 1.05;
}

.teacher_card a {
  text-decoration: none;
  color: var(--color-primary-dark);
}

.teacher_card h2 {
  font-size: 2.2rem;
  padding: 0.1rem 1rem;
  margin-top: 1rem;
  width: fit-content;
  position: relative;
  overflow: hidden;
}

.teacher_card h2::before {
  position: absolute;
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  background-color: var(--color-primary);
  top: 0;
  left: 0;
  z-index: -1;
  transform: translateX(-90%) skew(-20deg);
  transition: 0.2s all;
}

.teacher_card h2:hover::before {
  transform: translateX(-3%) skew(-20deg);
}

.teacher_card p {
  font-size: 2rem;
}

@media screen and (max-width: 710px) {
  .bio_btn:hover {
    scale: 1;
  }
}

@media screen and (max-width: 600px) {
  .teacher_card img {
    max-width: 35rem;
  }
}

@media screen and (max-width: 350px) {
  .teacher_card img {
    max-width: 30rem;
  }
}
