.location_frame {
  position: relative;
  padding-top: 20rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 2048px;
}

.banda1 {
  position: absolute;
  top: -200px;
  left: 0;
  z-index: 200;
  scale: 1.5;
}

.banda2 {
  position: absolute;
  top: -80px;
  z-index: 200;
  right: 10rem;
  scale: 1.2;
}

.location_infos {
  background-color: var(--color-primary);
  padding: 10rem 2rem;
  z-index: 100;
}

.location_infos h1,
.location_infos h2,
.location_infos h3 {
  font-size: 3rem;
}

.location_infos p {
  font-size: 2rem;
  margin: 2rem 0;
  max-width: 70%;
}

.carousel_img {
  max-width: 70rem;
}

@media screen and (max-width: 1225px) {
  .banda1,
  .banda2 {
    scale: 0.9;
    right: 0;
  }

  .banda1 {
    top: -150px;
    left: -100px;
  }
}

@media screen and (max-width: 860px) {
  .banda1 {
    display: none;
  }
}

@media screen and (max-width: 550px) {
  .banda2 {
    scale: 0.7;
    right: -50px;
    top: -30px;
  }

  .location_infos h1,
  .location_infos h2,
  .location_infos h3 {
    font-size: 2rem;
  }

  .location_infos p {
    font-size: 1.8rem;
    max-width: 100%;
  }
}

@media screen and (max-width: 450px) {
  .banda2 {
    right: -95px;
  }

  .location_frame {
    overflow: hidden;
  }
}
