.nav {
  display: none;
}

.hamburgerBtn {
  display: none;
  background-color: transparent;
  width: 40px;
  height: 40px;
  z-index: 4000;
  position: fixed;
  top: 20px;
  right: 20px;
  border: none;
}

.span1,
.span2,
.span3 {
  border: 1px solid var(--color-primary-dark);
  display: block;
}

.span1 {
  transform: translateY(-7px);
}

.span3 {
  transform: translateY(7px);
}

.navList {
  background-color: var(--color-primary);
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 280;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.navList a {
  font-size: 2rem;
  text-decoration: none;
  color: var(--color-primary-dark);
  font-weight: bold;
  padding: 1rem 30%;
}

.navList.isClosed {
  animation: slideOut 0.8s cubic-bezier(0.85, 0, 0.15, 1) forwards;
}

.navList.isOpen {
  animation: slideIn 0.8s cubic-bezier(0.85, 0, 0.15, 1) forwards;
}

@keyframes slideIn {
  from {
    left: 100%;
  }
  to {
    left: 0%;
  }
}
@keyframes slideOut {
  from {
    left: 0%;
  }
  to {
    left: 100%;
  }
}

.span1.isOpen {
  animation: span1rotateIn 0.25s linear forwards;
}
.span2.isOpen {
  animation: span2rotateIn 0.25s linear forwards;
}
.span3.isOpen {
  animation: span3fadeOut 0.1s linear forwards;
}

@keyframes span1rotateIn {
  from {
  }
  to {
    transform: rotate(-45deg);
  }
}

@keyframes span2rotateIn {
  from {
  }
  to {
    transform: rotate(45deg) translateY(-2px);
  }
}

@keyframes span3fadeOut {
  from {
    opacity: 100%;
  }
  to {
    opacity: 0%;
  }
}

.span1.isClosed {
  animation: span1rotateOut 0.25s linear forwards;
}
.span2.isClosed {
  animation: span2rotateOut 0.25s linear forwards;
}
.span3.isClosed {
  animation: span3fadeIn 0.1s linear forwards;
}

@keyframes span1rotateOut {
  from {
    transform: rotate(-45deg);
  }
  to {
  }
}

@keyframes span2rotateOut {
  from {
    transform: rotate(45deg) translateY(-2px);
  }
  to {
  }
}

@keyframes span3fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media screen and (max-width: 850px) {
  .nav {
    display: block;
    position: relative;
  }

  .hamburgerBtn {
    display: block;
  }
}
