.wrapper {
  background-color: var(--color-primary);
  display: flex;
  justify-content: space-evenly;
  padding-top: 5rem;
  padding-bottom: 2rem;
  color: var(--color-primary-dark);
  z-index: 200;
  position: relative;
}

.wrapper h1 {
  font-size: 3rem;
  letter-spacing: 2px;
  margin-bottom: 1rem;
}

.wrapper p {
  font-size: 1.8rem;
}

.left {
  display: flex;
  align-items: center;
}

.left div {
  display: flex;
  flex-direction: column;
  margin-left: 2rem;
}

.logo {
  max-height: 300px;
  border-radius: 2px;
}

.header_photo {
  max-width: 500px;
  border-radius: 10px;
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.28);
}

.wave {
  width: 100%;
  transform: translateY(-1px);
}

@media screen and (max-width: 1370px) {
  .header_photo {
    max-width: 280px;
  }

  .logo {
    max-height: 150px;
  }
}

@media screen and (max-width: 740px) {
  .header_photo {
    display: none;
  }
}

@media screen and (max-width: 850px) {
  .wrapper {
    transform: translateY(-20px);
  }

  .wave {
    transform: translateY(-22px);
  }
}

@media screen and (max-width: 490px) {
  .left {
    padding: 0 1rem;
  }

  .logo {
    max-width: 15rem;
  }

  .wrapper h1 {
    font-size: 2.6rem;
  }
}

@media screen and (max-width: 380px) {
  .logo {
    max-width: 13rem;
  }

  .wrapper h1 {
    font-size: 2.4rem;
  }

  .wrapper p {
    font-size: 1.6rem;
  }
}
