.about {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5rem 3rem;
}

.about h1 {
  font-size: 3rem;
  margin-bottom: 8rem;
  position: relative;
}

.about h1::before {
  position: absolute;
  content: "";
  display: block;
  width: 55%;
  height: 2px;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--color-primary);
}

.about_info {
  max-width: 900px;
}

.about p {
  font-size: 2rem;
  animation: animateInfo 1s 0.15s forwards linear;
  opacity: 0;
}

@keyframes animateInfo {
  from {
    transform: translateY(100px);
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
}
