/* general setup */

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&display=swap");

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
  box-sizing: border-box;
  background-color: var(--color-primary-light);
  font-family: "Open Sans", sans-serif;
  color: var(--color-primary-dark);
}

/* variables */
:root {
  --color-primary: #74c2c4;
  --color-primary-light: #f6f6f6;
  --color-primary-dark: #414143;
}

.carousel-root {
  width: fit-content;
  margin: 3rem 0;
}
