.gridCard {
  height: 20rem;
  position: relative;
  font-size: 3rem;
  color: var(--color-primary);
  transition: all 0.1s linear;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.gridCard p {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-image: linear-gradient(
    to left,
    rgba(32, 32, 32, 0.064),
    rgba(0, 0, 0, 0.612)
  );
  width: max-content;
  padding: 0.5rem 1rem;
}

.gridCard:hover {
  cursor: pointer;
  font-size: 3.3rem;
}

.gridCard img {
  width: 100%;
  filter: blur(1.5px) brightness(80%);
}
