.courseGrid {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  gap: 1.5rem;
  padding: 4rem 0.5rem;
}

@media (min-width: 600px) {
  .courseGrid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 900px) {
  .courseGrid {
    grid-template-columns: repeat(3, 1fr);
  }
}
