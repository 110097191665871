.map {
  width: 120rem;
  height: 60rem;
  border-radius: 10px;
  margin: 0 auto;
  margin-bottom: 4rem;
}

.wrapper h2 {
  font-size: 3rem;
  font-weight: 300;
  text-align: center;
  padding: 3rem 0 5rem 0;
}

.wrapper {
  background-color: var(--color-primary);
  padding: 2rem 0;
}

.waveTop {
  rotate: 180deg;
  width: 100%;
  transform: translateY(-5px);
}

.waveBot {
  transform: scaleX(-1) translateY(-5px);
  width: 100%;
}

@media screen and (max-width: 1370px) {
  .wrapper {
    padding: 0.5rem 0;
  }

  .map {
    width: 100rem;
  }
}

@media screen and (max-width: 1024px) {
  .map {
    width: 80rem;
  }

  .wrapper h2 {
    padding: 3rem 2rem 5rem 2rem;
  }
}

@media screen and (max-width: 830px) {
  .wrapper {
    padding: 0.5rem 0;
  }
  .map {
    width: 60rem;
  }
}

@media screen and (max-width: 630px) {
  .map {
    width: 100%;
  }
}

@media screen and (max-width: 380px) {
  .wrapper h2 {
    font-size: 2.4rem;
    padding: 3rem 0.5rem 5rem 0.5rem;
  }
}

.map_wrapper {
  background-color: var(--color-primary);
  display: flex;
  align-items: center;
  justify-content: center;
}

iframe {
  margin: 5rem 0 10rem 0;
  border: none;
  border-radius: 5px;
  height: 600px;
  width: 80%;
  max-width: 200rem;
}

@media screen and (max-width: 850px) {
  iframe {
    margin: 5rem 0 10rem 0;
    border: none;
    border-radius: 5px;
    height: 600px;
    width: 100%;
  }
}
